import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { IconContext } from 'react-icons';

import {
    FaFacebookSquare,
    FaTwitterSquare,
    FaInstagram,
    FaLinkedin,
    FaPinterest,
    FaSnapchat,
    FaSpotify,
    FaYoutube
} from 'react-icons/fa';

import OutboundLink from '../../helpers/outboundLink';

import './styles.scss';


class SocialIcon extends PureComponent {
    render() {
        const {
            colour,
            icon,
            link,
            venueName
        } = this.props;

        return (
            <OutboundLink
                className={`social-icon social-icon--${icon}`}
                eventLabel={`${icon} - ${venueName}`}
                href={link}
                target="_blank"
            >
                <IconContext.Provider
                    value={{ style: { fill: colour } }}
                >
                    {(() => {
                        switch (icon) {
                        case 'facebook':
                            return <FaFacebookSquare />;
                        case 'twitter':
                            return <FaTwitterSquare />;
                        case 'instagram':
                            return <FaInstagram />;
                        case 'linkedin':
                            return <FaLinkedin />;
                        case 'pinterest':
                            return <FaPinterest />;
                        case 'snapchat':
                            return <FaSnapchat />;
                        case 'spotify':
                            return <FaSpotify />;
                        case 'tiktok':
                            return (
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 12.05 11.95"
                                    style={{ fill: colour }}
                                >
                                    <path d="M10.54,0h-9A1.5,1.5,0,0,0,0,1.5v9A1.5,1.5,0,0,0,1.5,12h9a1.51,1.51,0,0,0,1.51-1.5V1.5A1.51,1.51,0,0,0,10.54,0Zm-1,5.28A3.73,3.73,0,0,1,8.15,5a4.37,4.37,0,0,1-.55-.32c0,1,0,2,0,3a2.56,2.56,0,0,1-.46,1.33,2.53,2.53,0,0,1-2,1.09,2.5,2.5,0,0,1-1.38-.35A2.55,2.55,0,0,1,2.53,7.73a4.17,4.17,0,0,1,0-.5A2.54,2.54,0,0,1,3.4,5.55,2.5,2.5,0,0,1,5.48,5c0,.5,0,1,0,1.5a1.19,1.19,0,0,0-1,.12,1.25,1.25,0,0,0-.46.6,1.23,1.23,0,0,0,0,.54,1.15,1.15,0,0,0,1.18,1,1.14,1.14,0,0,0,.94-.54.83.83,0,0,0,.13-.36c0-.61,0-1.21,0-1.81,0-1.37,0-2.73,0-4.09H7.55a2.09,2.09,0,0,0,.59,1.41,2.42,2.42,0,0,0,1.43.6Z" />
                                </svg>
                            )
                        case 'youtube':
                            return <FaYoutube />;
                        default:
                            return null;
                        }
                    })()}
                </IconContext.Provider>
            </OutboundLink>
        );
    }
}

SocialIcon.defaultProps = {
    colour: '#000'
};

SocialIcon.propTypes = {
    colour: PropTypes.string,
    icon: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired
};

export default SocialIcon;
