import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import Loader from '../Loader';
import './styles.scss';

class Button extends PureComponent {
    render() {
        const {
            text,
            handleClick,
            isSubmitting,
            style,
            type
        } = this.props;

        return (
            <button
                className="button"
                onClick={handleClick}
                type={type}
                style={style}
            >
                {isSubmitting ? (
                    <Loader />
                ) : (
                    <Fragment>
                        { text }
                    </Fragment>
                )}
            </button>
        );
    }
}

Button.defaultProps = {
    handleClick: () => {},
    isSubmitting: false,
    style: {},
    text: 'Submit',
    type: 'button'
};

Button.propTypes = {
    handleClick: PropTypes.func,
    isSubmitting: PropTypes.bool,
    style: PropTypes.object,
    text: PropTypes.string,
    type: PropTypes.string
};

export default Button;
