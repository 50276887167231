import React from 'react';
import PropTypes from 'prop-types';

const OutboundLink = React.forwardRef(({ children, ...props }, ref) => (
    <a
        ref={ref}
        className={props.className}
        href={props.href}
        target={props.target}
        onClick={(e) => {
            if (typeof props.onClick === 'function') {
                props.onClick(e);
            }
            let redirect = true;
            if (
                e.button !== 0
                || e.altKey
                || e.ctrlKey
                || e.metaKey
                || e.shiftKey
                || e.defaultPrevented
            ) {
                redirect = false;
            }
            if (props.target && props.target.toLowerCase() !== '_self') {
                redirect = false;
            }
            if (window.gtag) {
                window.gtag('event', 'Click', {
                    event_category: 'Outbound',
                    event_label: props.eventLabel || props.href,
                    transport_type: redirect ? 'beacon' : '',
                    event_callback: () => {
                        if (redirect) {
                            document.location = props.href;
                        }
                    }
                });
            } else if (redirect) {
                document.location = props.href;
            }

            return false;
        }}
    >
        {children}
    </a>
));

OutboundLink.defaultProps = {
    className: '',
    eventLabel: '',
    target: '_self',
    onClick: null
};

OutboundLink.propTypes = {
    children: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    className: PropTypes.string,
    eventLabel: PropTypes.string,
    href: PropTypes.string.isRequired,
    target: PropTypes.string,
    onClick: PropTypes.func
};

export default OutboundLink;
