import React, { PureComponent } from 'react';
import OpenEarLogo from '../OpenEarLogo';
import './styles.scss';

class OpenEarFooter extends PureComponent {
    render() {
        return (
            <div className="open-ear-footer">
                <div className="open-ear-footer__content">
                    <p>Powered by</p>
                    <OpenEarLogo
                        colour="#fff"
                    />
                </div>
            </div>
        );
    }
}


export default OpenEarFooter;
