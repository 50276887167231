import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { GiSoundWaves } from 'react-icons/gi';
import Loader from '../Loader';
import './styles.scss';

// Actions
import {
    getPlaylist
} from '../../actions/playlist';

class Playlist extends Component {
    componentDidMount() {
        const {
            goGetPlaylist,
            playlistToken
        } = this.props;

        goGetPlaylist(playlistToken);
    }

    render() {
        const {
            isLoading,
            currentTrack,
            lastPlayed,
            venueName,
            noPlayer,
            currentTrackBgColour,
            currentTrackTextColour,
            previousTrackBgColour,
            previousTrackTextColour
        } = this.props;

        return (
            <div className="playlist">
                {noPlayer || (
                    <Fragment>
                        <div
                            className="playlist__now"
                            style={{
                                backgroundColor: currentTrackBgColour,
                                color: currentTrackTextColour
                            }}
                        >
                            <h3 className="playlist__heading">
                                <GiSoundWaves />
                                Now Playing at {venueName}
                            </h3>
                            {isLoading ? (
                                <div className="playlist__loader">
                                    <Loader
                                        colour={currentTrackTextColour}
                                    />
                                </div>
                            ) : (
                                <Fragment>
                                    {(currentTrack && currentTrack.artist) && (
                                        <Fragment>
                                            <div className="playlist__current-artist">
                                                {currentTrack.artist}
                                            </div>
                                            <div>&mdash;</div>
                                            <div className="playlist__current-track">
                                                {currentTrack.title}
                                            </div>
                                        </Fragment>
                                    )}
                                </Fragment>

                            )}
                        </div>
                        <div
                            className="playlist__previous"
                            style={{
                                backgroundColor: previousTrackBgColour,
                                color: previousTrackTextColour
                            }}
                        >
                            <h3 className="playlist__heading">
                                <GiSoundWaves />
                                Last played
                            </h3>
                            {isLoading ? (
                                <div className="playlist__loader">
                                    <Loader
                                        colour={previousTrackTextColour}
                                    />
                                </div>
                            ) : (
                                <Fragment>
                                    {(lastPlayed && lastPlayed.length > 0) && (
                                        <ul className="playlist__last-played">
                                            {lastPlayed.map((track, index) => {
                                                if (index < 10) {
                                                    return (
                                                        <li
                                                            className="playlist__last-played-track"
                                                            key={`${index}-${track.id}`}
                                                            style={{
                                                                borderBottomColor: previousTrackTextColour
                                                            }}
                                                        >
                                                            {track.artist}
                                                            &nbsp;&ndash;&nbsp;
                                                            {track.title}
                                                        </li>
                                                    );
                                                }
                                                return null;
                                            })}
                                        </ul>
                                    )}
                                </Fragment>
                            )}
                        </div>
                    </Fragment>
                )}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        isLoading: state.playlistState.isLoading,
        currentTrack: state.playlistState.currentTrack,
        lastPlayed: state.playlistState.lastPlayed,
        noPlayer: state.playlistState.noPlayer
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        goGetPlaylist: (token) => {
            dispatch(getPlaylist(token));
        }
    };
};

Playlist.defaultProps = {
    isLoading: true,
    currentTrack: {},
    lastPlayed: [],
    venueName: 'Open Ear',
    noPlayer: false,
    currentTrackBgColour: '#d97934',
    currentTrackTextColour: '#fff',
    previousTrackBgColour: '#4ba0b3',
    previousTrackTextColour: '#fff'
};

Playlist.propTypes = {
    goGetPlaylist: PropTypes.func.isRequired,
    playlistToken: PropTypes.string.isRequired,
    isLoading: PropTypes.bool,
    currentTrack: PropTypes.object,
    lastPlayed: PropTypes.array,
    venueName: PropTypes.string,
    noPlayer: PropTypes.bool,
    currentTrackBgColour: PropTypes.string,
    currentTrackTextColour: PropTypes.string,
    previousTrackBgColour: PropTypes.string,
    previousTrackTextColour: PropTypes.string
};

export default connect(mapStateToProps, mapDispatchToProps)(Playlist);
