import * as types from '../constants/actiontypes';

import addToMailchimp from 'gatsby-plugin-mailchimp';

export const submittingEmailForm = () => {
    return {
        type: types.SUBMITTING_EMAIL_FORM
    };
};

export const emailFormSuccess = () => {
    return {
        type: types.EMAIL_FORM_SUCCESS
    };
};

export const emailFormError = (errors) => {
    return {
        type: types.EMAIL_FORM_ERROR,
        errors
    };
};

export const submitEmailForm = (values, mailchimpEndpoint, venueName) => {
    return (dispatch) => {
        dispatch(submittingEmailForm());

        return addToMailchimp(
            values.email,
            {
                FNAME: values.name
            },
            mailchimpEndpoint
        )
            .then((result) => {
                // Mailchimp always returns a 200 response
                // So we check the result for MC errors & failures
                if (result.result !== 'success') {
                    console.log(result.msg);

                    if (typeof window !== 'undefined' && window.gtag) {
                        window.gtag(
                            'event',
                            'Error',
                            {
                                'event_category': 'Email Form',
                                'event_label': venueName
                            }
                        );
                    }

                    return dispatch(emailFormError([result.msg]));
                } else {
                    if (typeof window !== 'undefined' && window.gtag) {
                        window.gtag(
                            'event',
                            'Success',
                            {
                                'event_category': 'Email Form',
                                'event_label': venueName
                            }
                        );
                    }

                    return dispatch(emailFormSuccess());
                }
            })
            .catch((err) => {
                // Network failures, timeouts, etc
                console.log(err);

                if (typeof window !== 'undefined' && window.gtag) {
                    window.gtag(
                        'event',
                        'Error',
                        {
                            'event_category': 'Email Form',
                            'event_label': venueName
                        }
                    );
                }

                return dispatch(emailFormError([err]));
            });
    };
};
