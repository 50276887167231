// import { trans } from '../../../common/helpers/translationsWrapper.js';
import * as EmailValidator from 'email-validator';

const requiredFields = [
    'name',
    'email'
];

const validate = (values) => {
    const errors = [];

    requiredFields.forEach((field) => {
        if (!values[field]) {
            errors[field] = 'Required field';
        }
    });

    if (!values.email) {
        errors.email = 'Required field';
    } else if (!EmailValidator.validate(values.email)) {
        errors.email = 'Email is not in a valid format';
    }

    return errors;
};

export default validate;
