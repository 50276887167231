import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import './styles.scss';

class RenderField extends Component {
    render() {
        const field = this.props;

        return (
            <Fragment>
                {field.type === 'textarea' ? (
                    <textarea
                        className="form-input"
                        name={field.name}
                        placeholder={field.label}
                        type={field.type}
                        onChange={(value) => {
                            field.input.onChange(value);
                        }}
                    />
                ) : (
                    <input
                        className="form-input"
                        name={field.name}
                        placeholder={field.label}
                        type={field.type}
                        onChange={(value) => {
                            field.input.onChange(value);
                        }}
                    />
                )}

                {field.meta.touched && field.meta.error && (
                    <span className="form-input__error">
                        {field.meta.error}
                    </span>
                )}
            </Fragment>
        );
    }
}

class FormInput extends Component {
    render() {
        const {
            label,
            name,
            type
        } = this.props;

        return (
            <Field
                component={RenderField}
                label={label}
                name={name}
                type={type}
            />
        );
    }
}

FormInput.defaultProps = {
    label: 'Input',
    name: 'Input',
    type: 'input'
};

FormInput.propTypes = {
    label: PropTypes.string,
    name: PropTypes.string,
    type: PropTypes.string
};

export default FormInput;
