import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import SocialIcon from '../SocialIcon';
import './styles.scss';

class ConnectSocial extends PureComponent {
    render() {
        const {
            bgColour,
            iconColour,
            venueName,
            social
        } = this.props;

        return (
            <div
                className="connect-social"
                style={{
                    backgroundColor: bgColour,
                    color: iconColour
                }}
            >
                <h2 className="connect-social__heading">
                    {social.socialText || <Fragment>Follow {venueName}</Fragment>}
                </h2>
                <div className="connect-social__icons">
                    {social.facebookLink && (
                        <SocialIcon
                            colour={iconColour}
                            icon="facebook"
                            link={social.facebookLink}
                            venueName={venueName}
                        />
                    )}
                    {social.twitterLink && (
                        <SocialIcon
                            colour={iconColour}
                            icon="twitter"
                            link={social.twitterLink}
                            venueName={venueName}
                        />
                    )}
                    {social.instagramLink && (
                        <SocialIcon
                            colour={iconColour}
                            icon="instagram"
                            link={social.instagramLink}
                            venueName={venueName}
                        />
                    )}
                    {social.linkedInLink && (
                        <SocialIcon
                            colour={iconColour}
                            icon="linkedin"
                            link={social.linkedInLink}
                            venueName={venueName}
                        />
                    )}
                    {social.pinterestLink && (
                        <SocialIcon
                            colour={iconColour}
                            icon="pinterest"
                            link={social.pinterestLink}
                            venueName={venueName}
                        />
                    )}
                    {social.youTubeLink && (
                        <SocialIcon
                            colour={iconColour}
                            icon="youtube"
                            link={social.youTubeLink}
                            venueName={venueName}
                        />
                    )}
                    {social.tikTokLink && (
                        <SocialIcon
                            colour={iconColour}
                            icon="tiktok"
                            link={social.tikTokLink}
                            venueName={venueName}
                        />
                    )}
                </div>
            </div>
        );
    }
}

ConnectSocial.defaultProps = {
    bgColour: '#f7f7f7',
    iconColour: '#000',
    venueName: 'Open Ear'
};

ConnectSocial.propTypes = {
    bgColour: PropTypes.string,
    iconColour: PropTypes.string,
    social: PropTypes.object.isRequired,
    venueName: PropTypes.string
};

export default ConnectSocial;
