const transform = (obj, form, namespace) => {
    const fd = form || new FormData();
    let formKey;
    let property;

    for (property in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, property)) {
            formKey = namespace ? `${namespace}[${property}]` : property;

            if (typeof obj[property] === 'object' && !(obj[property] instanceof File)) {
                transform(obj[property], fd, formKey);
            } else {
                fd.append(formKey, obj[property]);
            }
        }
    }

    return fd;
};

export default transform;
