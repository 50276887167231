import 'formdata-polyfill';

/**
 * Helper to encode Form Data when working with PUT requests and Axios.
 * Axios has issues with the application/x-www-form-urlencoded content type.
 */

const urlEncodeFormData = (formData) => {
    let s = '';

    const encode = (s) => {
        return encodeURIComponent(s).replace(/%20/g,'+');
    };

    Array.from(formData.entries()).map((pair) => {
        if (typeof pair[1] === 'string') {
            s += `${(s ? '&' : '')} ${encode(pair[0])}=${encode(pair[1])}`;
        }
    });

    return s;
};

export default urlEncodeFormData;
