import * as types from '../constants/actiontypes';

export const loadingPlaylist = () => {
    return {
        type: types.LOADING_PLAYLIST
    };
};

export const currentTrack = (data) => {
    return {
        type: types.PLAYLIST_CURRENT,
        data
    };
};

export const lastPlayed = (data) => {
    return {
        type: types.PLAYLIST_LAST_PLAYED,
        data
    };
};

export const playlistError = () => {
    return {
        type: types.PLAYLIST_ERROR
    };
};

export const getPlaylist = (token) => {
    return (dispatch) => {
        dispatch(loadingPlaylist());

        const socket = new WebSocket('wss://ws.player.openearmusic.com');

        socket.onopen = () => {
            const data = {
                token,
                event: 'webInit',
                data: null
            };

            const ping = setInterval(() => {
                const pingStart = () => {
                    socket.send(JSON.stringify(data));
                };
                pingStart();
            }, 10000);

            socket.send(JSON.stringify(data));
        };

        socket.onclose = () => {
            dispatch(getPlaylist(token));
        };

        socket.onmessage = (e) => {
            const data = JSON.parse(e.data);

            switch (data.event) {
            case 'currentTrack':
                dispatch(currentTrack(data.data));
                break;
            case 'lastPlayed':
                dispatch(lastPlayed(data.data));
                break;
            case 'noPlayer':
                dispatch(playlistError());
                break;
            default:
                return null;
            }
            return null;
        };

        socket.onerror = (err) => {
            console.log('socket error', err);
        };
    };
};
