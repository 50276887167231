import React, { Fragment, PureComponent } from 'react';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';

import '../styles/pop-golf.scss';

import validate from '../helpers/validation';
import transform from '../serializers/formDataTransformer';
import urlEncodeFormData from '../serializers/urlEncodeFormData';
import { submitEmailForm } from '../actions/email-form';

import FormInput from '../components/FormInput';
import Button from '../components/Button';
import Playlist from '../components/Playlist';
import ConnectSocial from '../components/ConnectSocial';
import OpenEarFooter from '../components/OpenEarFooter';

class PopGolfConnect extends PureComponent {
    render() {
        const {
            emailFormSuccess,
            errors,
            goSubmitEmailForm,
            handleSubmit,
            isSubmitting
        } = this.props;

        return (
            <Fragment>
                <div className="pop-golf-fixed-bg" />
                <div className="pop-golf-wrapper pop-golf-wrapper--connect">
                    <div className="pop-golf__logo">
                        <img
                            src="/pop-golf-assets/pop-golf-logo.png"
                            alt="Pop Golf"
                        />
                    </div>
                    <div className="pop-golf__intro-copy">
                        <p>Discover all the biggest songs right now and our favourite breakthrough artists.</p>
                        <p>
                            <strong>Updated monthly</strong>
                        </p>
                    </div>
                    <a
                        className="pop-golf__spotfy-link"
                        href="https://open.spotify.com/user/zzkd4ckv0bdasuujcuo6kdzie"
                    >
                        GET THE PLAYLIST
                    </a>
                    <div className="email-form">
                        <h3>GET FREE MUSIC NEWS</h3>

                        {emailFormSuccess ? (
                            <Fragment>
                                <p>Great! An email has been sent to your inbox.</p>
                            </Fragment>
                        ) : (
                            <Fragment>
                                {Object.keys(errors).length > 0 && (
                                    <p>There has been an error submitting the form. Please check your details and try again</p>
                                )}

                                <form onSubmit={handleSubmit(goSubmitEmailForm)}>
                                    <FormInput
                                        label="Full name"
                                        name="name"
                                        type="text"
                                    />
                                    <FormInput
                                        label="Email"
                                        name="email"
                                        type="text"
                                    />
                                    <p>(We promise we won&apos;t spam you)</p>
                                    <Button
                                        text="HIT ME"
                                        type="submit"
                                        isSubmitting={isSubmitting}
                                    />
                                </form>
                            </Fragment>
                        )}
                    </div>
                    <Playlist
                        currentTrackBgColour="#e5025e"
                        currentTrackTextColour="#fff"
                        playlistToken="ODQ4Mzs1NjQ0OzE2MjAzODEzNjU7sxqfPKxR7TnWreqaPfJML3MWb98xXWZUSdyZQ3MYLZY%3D"
                        venueName="Pop Golf"
                    />
                    <ConnectSocial
                        bgColour="transparent"
                        iconColour="#fff"
                        social={{
                            facebookLink: 'https://www.facebook.com/PopGolfUK',
                            instagramLink: 'https://www.instagram.com/popgolfuk',
                            socialIconColour: '#fff',
                            socialText: 'FOLLOW POPGOLF',
                            tikTokLink: 'https://www.tiktok.com/@popgolf',
                            twitterLink: 'https://twitter.com/PopgolfUK'
                        }}
                    />
                    <OpenEarFooter />
                </div>
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        isSubmitting: state.emailFormState.isSubmitting,
        emailFormSuccess: state.emailFormState.emailFormSuccess,
        errors: state.emailFormState.errors,
        form: 'pop-golf-email-form'
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        goSubmitEmailForm: (values) => {
            dispatch(
                submitEmailForm(
                    values,
                    'https://openearmusic.us2.list-manage.com/subscribe/post?u=d4600a742c302f2f68d4ace4e&amp;id=ed0b4af9d8',
                    'Pop Golf'
                )
            );
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)((reduxForm({
    enableReinitialize: true,
    validate
})(PopGolfConnect)));

// export default PopGolfLanding;
